// extracted by mini-css-extract-plugin
export var tileContent = "B_vR d_w d_H d_Z";
export var teamTextLeft = "B_vS d_dv";
export var teamTextCenter = "B_vT d_dw";
export var teamTextRight = "B_vV d_dx";
export var alignLeft = "B_rd d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "B_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "B_rf d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "B_vW d_dW";
export var teamContainerFull = "B_vX d_dT";
export var teamRowWrapper = "B_vY d_cc";
export var teamTileWrapper = "B_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "B_vZ d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "B_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "B_j5 d_j5 d_Z";
export var teamHoverNoGutters = "B_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "B_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "B_v0 d_j7";
export var teamInfoWrapperRound = "B_j7 d_j7";
export var teamInfoWrapper = "B_v1 d_j8 d_0";
export var teamInfoWrapperNoGutters = "B_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "B_jZ d_jZ";
export var teamImgWrapperAlt = "B_j0 d_j0";
export var teamImgWrapperNoGutters = "B_kb d_kb";
export var teamHeader = "B_v2 d_cw";
export var teamHeaderAlt = "B_v3 d_cw";
export var teamHeaderNoGutters = "B_v4 d_cw d_cD";