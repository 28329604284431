// extracted by mini-css-extract-plugin
export var alignLeft = "v_rd d_bG";
export var alignCenter = "v_bP d_bD";
export var alignRight = "v_rf d_bH";
export var textAlignLeft = "v_sG";
export var textAlignCenter = "v_sH";
export var textAlignRight = "v_sJ";
export var embedInnerWrapperDesign1 = "v_sK d_bM";
export var embed = "v_sL d_b1";
export var titleMargin = "v_sM d_cw d_dw";
export var subtitleMargin = "v_sN d_cs d_dw";
export var paragraphMargin = "v_sP d_cw d_dw";
export var SubtitleSmall = "v_pd y_pd y_ts y_tF";
export var SubtitleNormal = "v_pf y_pf y_ts y_tG";
export var SubtitleLarge = "v_pg y_pg y_ts y_tH";
export var BodySmall = "v_sQ y_sQ y_ts y_tM";
export var BodyNormal = "v_sR y_sR y_ts y_tN";
export var BodyLarge = "v_sS y_sS y_ts y_tP";