// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_vJ d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_vK d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_vL d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_vM d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_vN d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_vP d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_vQ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_vh y_vh";
export var heroExceptionNormal = "z_vj y_vj";
export var heroExceptionLarge = "z_vk y_vk";
export var Title1Small = "z_tY y_tY y_ts y_tt";
export var Title1Normal = "z_tZ y_tZ y_ts y_tv";
export var Title1Large = "z_t0 y_t0 y_ts y_tw";
export var BodySmall = "z_sQ y_sQ y_ts y_tM";
export var BodyNormal = "z_sR y_sR y_ts y_tN";
export var BodyLarge = "z_sS y_sS y_ts y_tP";